/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import { genEmailLink, TH_EMAIL_ADDRESS } from '../../constants/externalURLs';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import GlobalPresence from '../../components/GlobalPresence';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import Partner from '../../components/Partner';
import { partnersData } from './partnersData';

import s from './Contact.module.scss';

const propTypes = {
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/contact-us.png',
  ogTitle: 'Contact | Tech Holding',
  ogDesc:
    "Connect with TechHolding for inquiries, collaborations, or consultations. Reach out today and let's start a conversation about how we can help you achieve your goals.",
  ogPath: '/contact',
};

const updatedSeo = getSeoConfig(seoParams);

const Contact = ({ companyQuote }) => (
  <Layout
    currentPage="/contact"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
    footerDescription={companyQuote.footerDescription}
  >
    <div>
      <PageHeader>
        <h2>Hello there!</h2>
        <p className={s.paragraph}>
          Want us to work on your project? Let’s collaborate!
          <br />
          <a target="_blank" rel="noopener noreferrer" href={genEmailLink(TH_EMAIL_ADDRESS)}>
            {TH_EMAIL_ADDRESS}
          </a>
        </p>
      </PageHeader>
      <GlobalPresence />
      <Partner partnersImages={partnersData} />
    </div>
  </Layout>
);

Contact.propTypes = propTypes;
export default Contact;
